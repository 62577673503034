// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-projects-codi-js": () => import("./../../../src/pages/projects/codi.js" /* webpackChunkName: "component---src-pages-projects-codi-js" */),
  "component---src-pages-projects-food-recipes-js": () => import("./../../../src/pages/projects/foodRecipes.js" /* webpackChunkName: "component---src-pages-projects-food-recipes-js" */),
  "component---src-pages-projects-listme-app-js": () => import("./../../../src/pages/projects/ListmeApp.js" /* webpackChunkName: "component---src-pages-projects-listme-app-js" */),
  "component---src-pages-projects-listme-js": () => import("./../../../src/pages/projects/listme.js" /* webpackChunkName: "component---src-pages-projects-listme-js" */),
  "component---src-pages-projects-nextgenlondon-js": () => import("./../../../src/pages/projects/nextgenlondon.js" /* webpackChunkName: "component---src-pages-projects-nextgenlondon-js" */),
  "component---src-pages-projects-pophit-js": () => import("./../../../src/pages/projects/pophit.js" /* webpackChunkName: "component---src-pages-projects-pophit-js" */),
  "component---src-pages-projects-ramonas-js": () => import("./../../../src/pages/projects/ramonas.js" /* webpackChunkName: "component---src-pages-projects-ramonas-js" */),
  "component---src-pages-projects-wing-woman-lebanon-js": () => import("./../../../src/pages/projects/wingWomanLebanon.js" /* webpackChunkName: "component---src-pages-projects-wing-woman-lebanon-js" */),
  "component---src-templates-blog-post-js": () => import("./../../../src/templates/blogPost.js" /* webpackChunkName: "component---src-templates-blog-post-js" */)
}

